import React , { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
      <Suspense fallback={<h1>Loading profile...</h1>}>
    <App />
      </Suspense>

  </React.StrictMode>,
  document.getElementById('root')
);



