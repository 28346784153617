import React, {Suspense} from "react";
import { Stars } from '@react-three/drei'
import { Canvas } from "react-three-fiber";
import * as THREE from 'three';
import './App.css';
import Controls from "./components/Controls";
import DancingRogo from "./components/DancingRogo";


const App = () => {

    // Camera information
    const NEAR = 1, FAR = 200;
    const POSITION_COMPENSATION = -2

    // Calculation for shadow
    const SHADOW_VARIABLE = 8 // Shadow variable, 8.25

  return (
      <Canvas
          shadows
          dpr={[1, 1.5]}
          onCreated={({gl}) => {
              gl.shadowMap.enabled = true
              gl.shadowMap.type = THREE.PCFShadowMap
          }}>

{/*          <Suspense fallback={null}>
              <Player />
          </Suspense>*/}


          <Controls/>
          <Stars />

{/*          <hemisphereLight skyColor={"blue"} groundColor={0xffffff} intensity={0.7} position={[0, 50, 0]}/>*/}
          <directionalLight
              position={[-10, 10, 10]}
              shadow-camera-left={SHADOW_VARIABLE * -1}
              shadow-camera-bottom={SHADOW_VARIABLE * -1}
              shadow-camera-right={SHADOW_VARIABLE}
              shadow-camera-top={SHADOW_VARIABLE}
              shadow-camera-near={NEAR}
              shadow-camera-far={FAR}
              castShadow
          />

          <directionalLight
              position={[10, 10, -10]}
              shadow-camera-left={SHADOW_VARIABLE * -1}
              shadow-camera-bottom={SHADOW_VARIABLE * -1}
              shadow-camera-right={SHADOW_VARIABLE}
              shadow-camera-top={SHADOW_VARIABLE}
              shadow-camera-near={NEAR}
              shadow-camera-far={FAR}
              castShadow
          />


          <Suspense fallback={null}>
              <DancingRogo position={[0,POSITION_COMPENSATION,0]}/>
          </Suspense>




      </Canvas>
  );
}

export default App;

