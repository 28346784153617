import { Canvas, extend, useFrame, useThree } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useRef } from "react";


extend({OrbitControls})

const Controls = () => {
    const orbitRef = useRef();
    const {camera, gl} = useThree();

    useFrame(() => {
        orbitRef.current.update();
    });

    return (
        <orbitControls
            autoRotate
            autoRotateSpeed={1}
            minPolarAngle={Math.PI / 3}
            maxPolarAngle={Math.PI / 2}
            args={[camera, gl.domElement]}
            ref={orbitRef}
        />

    )
}

export default Controls;
