import React, {useState, useRef, useEffect} from "react";
import * as THREE from 'three';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {useFrame, useLoader} from "react-three-fiber";


const DancingRogo = ({...props}) => {
    const GROUP = useRef()
    console.log("nodes1")
    const { nodes, materials, animations } = useLoader(GLTFLoader, "/Rogo.glb")
    console.log("nodes2")
    console.log(nodes)
    console.log(animations)
    console.log("materials")
    console.log(materials)
    const ACTIONS = useRef()
    const [MIXER] = useState(() => new THREE.AnimationMixer())

    console.log("nodes")
/*    console.log(nodes)*/

    // -------- Start Animation --------
    useEffect(() => {
        ACTIONS.current = {idle: MIXER.clipAction(animations[0], GROUP.current)}
        ACTIONS.current.idle.play()
        return () => animations.forEach((clip) => MIXER.uncacheClip(clip))
    }, [MIXER, animations, nodes])

    useFrame((state, delta) => {
        MIXER.update(delta)
    })
    // -------- End Animation --------

    return (
        <group ref={GROUP} {...props} dispose={null}>
            <primitive object={nodes["Armature"]} scale={[0.02,0.02,0.02]}>
{/*                <skinnedMesh
                    receiveShadow
                    castShadow
                    material={materials['Boy01_Body_MAT.002']}
                    geometry={nodes['Boy01_Body_Geo'].geometry}
                    skeleton={nodes['Boy01_Body_Geo'].skeleton}
                >
                </skinnedMesh>*/}
            </primitive>
        </group>
    )


};


export default DancingRogo;
